import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    useInput, required, ImageInput, ImageField
} from 'react-admin';
import { Divider, Box, Grid  } from '@mui/material';

export const AccountInputs = ({link, username}) => {

    return (<Box flex="1" mt={-1}>
        {username !== "admin" ? <TextInput label="Имя пользователя (латиницей)" source="username" fullWidth validate={required()}/> : null}

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Имя" source="first_name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фамилия и/или Отчество" source="last_name" fullWidth validate={required()} />
            </Grid>
        </Grid>

        <Divider />
        
        {link !== "edit" ? <TextInput label="Пароль" source="password" fullWidth validate={required()}/> : null}

        <ImageInput source="picture" label="Аватар" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        {/*<TextInput source="avatar" fullWidth />
          <ImageInput source="avatar" label="Related pictures">
            <ImageField source="src" title="title" />
        </ImageInput>*/}

    </Box>);
};

const Spacer = () => <Box width={20} component="span" />;

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList
} from 'react-admin';
import { Dialog, Grid } from '@mui/material';

import { stageChoices } from './stages';
import { typeChoices } from './types';
import { Deal } from '../types';

const validateRequired = required();

const DealCreate = ({ open }) => {
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const [currentFunnel, setCurrentFunnel] = useState();

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'title', order: 'ASC' },
        }
    );
    const { data: companies,  isLoading: isLoadingCompanies } = useGetList(
        'companies',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'title', order: 'ASC' },
        }
    );
    const [currentCompany, setCurrentCompany] = useState();

    useEffect(() => {
      const storedFunnelId = localStorage.getItem('currentFunnelId');
      if (funnels && storedFunnelId) {
        const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
        setCurrentFunnel(selectedFunnel || funnels[0]);
      } else if (funnels && funnels.length > 0) {
        setCurrentFunnel(funnels[0]);
      }
    }, [funnels]);

    useEffect(() => {
      if (currentFunnel) {
        localStorage.setItem('currentFunnelId', currentFunnel.id);
      }
    }, [currentFunnel]);

    useEffect(() => {
      setCurrentCompany(companies[0]);
    }, [companies]);

    if(isLoadingFunnels || !currentFunnel) return null;

    const handleSelectChangeFunnel = (value) => {
         console.log('Selected value:', value.target.value);
         setCurrentFunnel(funnels.find((item) => item.id === value.target.value));
    };

    const handleSelectChangeCompany = (value) => {
         console.log('Selected value:', value.target.value);
         setCurrentCompany(companies.find((item) => item.id === value.target.value));
    };

    const handleClose = () => {
        redirect('/deals');
    };

    const onSuccess = (deal) => {
        redirect('/deals');
        // increase the index of all deals in the same stage as the new deal
        dataProvider
            .getList('deals', {
                pagination: { page: 1, perPage: 5000 },
                sort: { field: 'id', order: 'ASC' },
                filter: { stage: deal.stage },
            })
            .then(({ data: deals }) =>
                Promise.all(
                    deals
                        .filter(oldDeal => oldDeal.id !== deal.id)
                        .map(oldDeal =>
                            dataProvider.update('deals', {
                                id: oldDeal.id,
                                data: { index: oldDeal.index + 1 },
                                previousData: oldDeal,
                            })
                        )
                )
            );
    };

    return (
            <Create
                resource="deals"
                mutationOptions={{ onSuccess }}
                sx={{ /*width: 500, '& .RaCreate-main': { mt: 0 }*/ }}
            >
                <SimpleForm defaultValues={{ index: 0 }}>
                    <TextInput
                        source="name"
                        label="Название сделки"
                        fullWidth
                        validate={validateRequired}
                    />
                    <TextInput
                        source="description"
                        label="Описание"
                        multiline
                        rows={3}
                        fullWidth
                    />
                    {/*<ReferenceInput source="company_id" reference="companies">
                        <AutocompleteInput
                            label="Компания"
                            optionText="name"
                            fullWidth
                            validate={validateRequired}
                        />
                    </ReferenceInput>*/}
                    <SelectInput
                        source="company_id"
                        label="Компания"
                        choices={companies}
                        fullWidth
                        validate={validateRequired}
                        onChange={handleSelectChangeCompany}
                    />
                    <SelectInput
                        source="funnel"
                        label="Воронка"
                        choices={funnels}
                        fullWidth
                        validate={validateRequired}
                        onChange={handleSelectChangeFunnel}
                        defaultValue={currentFunnel.id}
                    />
                    <SelectInput
                        source="stage"
                        label="Этап"
                        choices={currentFunnel.stages}
                        fullWidth
                        validate={validateRequired}
                    />
                    <SelectInput
                        source="type"
                        label="Тип"
                        choices={typeChoices}
                        fullWidth
                    />
                    <NumberInput label="Бюджет, руб." source="amount" fullWidth defaultValue={0} />
 <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <DateTimeInput label="Время начала" source="start_at" defaultValue={new Date()}/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <ReferenceInput source="sales_id" reference="sales" fullwidth>
                <SelectInput
                    label="Менеджер"
                    helperText={false}
                    optionText={(sales: any) =>
                        `${sales.first_name} ${sales.last_name} ${sales.username}`
                    }
                    fullwidth
                />
            </ReferenceInput>
            </Grid>
        </Grid>
                </SimpleForm>
            </Create>
    );
};

export default DealCreate;

import * as React from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useShowContext,
    EditButton
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { Avatar } from '../contacts/Avatar';
import '../crm.css';

const AccountShow = () => (
    <ShowBase>
        <AccountShowContent />
    </ShowBase>
);

const AccountShowContent = () => {
    const { record, isLoading } = useShowContext();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Avatar />
                            <Box ml={2} flex="1">
                                <Typography variant="h5">
                                    {record.first_name} {record.last_name}
                                </Typography>
                                <Typography variant="body2">
                                    {record.username}
                                </Typography>
                                {localStorage.getItem('username') == "admin" ?  <EditButton label="Редактировать пользователя" /> : null}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default AccountShow;
import { /*AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS,*/ AuthProvider } from 'react-admin';
import axios from 'axios';
import decodeJwt from 'jwt-decode';

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        let data = JSON.stringify({ username, password });

        return axios.post(/*"https://server.prostocrm.pro/login"*/"https://visionto.ru/login"/*"http://188.120.254.193:3000/login"*/, data, {
          headers: {
            'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
          }
        }).then(res => {
          if (res.data.error || res.status !== 200) {
            throw new Error(res.data.error);
          }
          else {
            const token = res.data.token;
            const decodedToken = decodeJwt(token);
            //const role = decodedToken.identity.role;
            localStorage.setItem('token', token);
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('auth', JSON.stringify(res.data));
            //localStorage.setItem('role', role);
            return Promise.resolve();
          }
        });
    },
    logout: () => {
       localStorage.removeItem('token');
       localStorage.removeItem('username');
       localStorage.removeItem('auth');
       //localStorage.removeItem('role');
       return Promise.resolve();
    },
    checkError: (params) => {
        const { status } = params;
        if (status === 401 || status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('auth');
          //localStorage.removeItem('role');
          return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' }),
    getPermissions: () => {
        //const role = localStorage.getItem('role');
        //return role ? Promise.resolve(role) : Promise.reject();
        Promise.resolve([]);
    },
    getIdentity: () => {
        const { id, username, first_name, last_name, avatar } = JSON.parse(localStorage.getItem('auth') ?? '');
        return Promise.resolve({
            id: id,
            fullName: first_name + " " + last_name,
            avatar: avatar
        });
    },
};

import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';

const AccountListContent = () => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>
            <List>
                {accounts.map(account => (
                    <RecordContextProvider key={account.id} value={account}>
                        <ListItem
                            button
                            component={Link}
                            to={`/sales/${account.id}/show`}
                        >
                            <ListItemIcon>
                                {account.username !== "admin" ? <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(account.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(account.id);
                                    }}
                                /> : null}
                            </ListItemIcon>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${account.first_name} ${account.last_name}`}
                                secondary={account.username}
                            />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const AccountListActions = () => (
    <TopToolbar>
        <ExportButton />
        <CreateButton
            variant="contained"
            label="Новый пользователь"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const AccountList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={<AccountListActions />}
            perPage={1000}
            pagination={<Pagination rowsPerPageOptions={[1000, 3000, 5000, 10000]} />}
        >
            <AccountListContent />
        </RaList>
    ) : null;
};

export default AccountList;

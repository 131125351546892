import { Admin, Login } from 'react-admin';
import React from 'react';
import { useLogin } from 'react-admin';
import { TextField, Button, Box } from '@mui/material';
import Logo from '../resources/logo.png';
import Logo1 from '../resources/logo3.png';

const MyLoginPage = () => {
  const login = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform your login logic here
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    // Call the login function with the provided credentials
    login({ username, password });
  };

     return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundImage: /*`url('https://www.digitaltaxmatters.co.uk/wp-content/uploads/2019/06/business-support.jpg')`,*/`url('https://brimp.ru/wp-content/uploads/2023/04/Шапка-профиля-шаурменная-.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div>
          <img src={Logo} alt="Brimp CRM Logo" style={{ height: '60px', marginTop: '10px' }} />
        </div>

        <form onSubmit={handleSubmit}>
          <TextField
            id="username"
            name="username"
            label="Имя пользователя"
            variant="outlined"
            margin="normal"
            required
            fullWidth
          />

          <TextField
            id="password"
            name="password"
            label="Пароль"
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
          />

          <Button variant="contained" type="submit" fullWidth sx={{ marginTop: 2 }}>
            Войти
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default MyLoginPage;
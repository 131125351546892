export const types = [
    'Консультация',
    'Открытие',
    'Разработка рецептур',
    'Открытие пекарни',
    'Открытие производства',
    'Другие запросы'
];

export const typeChoices = types.map(type => ({ id: type, name: type }));

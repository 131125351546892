import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    EditButton,
    TopToolbar,
    CreateButton,
    Datagrid,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    DateField,
    Link,
    ShowButton,
    RichTextField
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import '../crm.css';
//const Imap = require('imap');
//import ImapClient from 'emailjs-imap-client';
//const ImapEmails = require('imap-emails');


const MailListContent = () => {
    const {
        data: mails,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>
            <List>
                {mails.map(mail => (
                    <RecordContextProvider key={mail.id} value={mail}>
                        <ListItem
                            button
                            component={Link}
                            to={`/mails/${mail.id}/show`}
                        >
                            <ListItemIcon>
                                {localStorage.getItem('username') == "admin" ? <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(mail.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(mail.id);
                                    }}
                                /> : null}
                            </ListItemIcon>
                            <ListItemText
                                primary={mail.parts[0].body.subject}
                                secondary={`${mail.parts[0].body.from} ${mail.parts[0].body.date}`}
                            />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const MailsList = () => {
    const { identity } = useGetIdentity();

    return identity ? (
     <RaList  
        //actions={<TagsListActions />}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}>
       <MailListContent />
     </RaList>    
   ) : null;
};

export default MailsList;
import * as React from 'react';
import {
    FilterList,
    FilterLiveSearch,
    FilterListItem,
    useGetIdentity,
    useGetList,
} from 'react-admin';
import { Box, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { endOfYesterday, startOfWeek, startOfMonth, subMonths } from 'date-fns';

import { Status } from '../misc/Status';
import '../crm.css';

export const ContactListFilter = ({ className }) => {
    const { identity } = useGetIdentity();
    const { data } = useGetList('tags', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'name', order: 'ASC' },
    });
    return (
        <Box width="13em" minWidth="13em" style={{margin: '1em'}} order={-1} mr={2} mt={7} class={className}>
            <FilterLiveSearch
                sx={{
                    display: 'block',
                    '& .MuiFilledInput-root': { width: '100%' },
                }}
            />
            {/*<FilterList label="Последние изменения" icon={<AccessTimeIcon />}>
                <FilterListItem
                    label="Сегодня"
                    value={{
                        last_seen: endOfYesterday().toISOString(),
                        //last_seen_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="На этой неделе"
                    value={{
                        last_seen_gte: startOfWeek(new Date()).toISOString(),
                        last_seen_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="В этом месяце"
                    value={{
                        last_seen_gte: undefined,
                        last_seen_lte: startOfWeek(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="В прошлом месяце"
                    value={{
                        last_seen_gte: undefined,
                        last_seen_lte: startOfMonth(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="До прошлого месяца"
                    value={{
                        last_seen_gte: undefined,
                        last_seen_lte: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                    }}
                />
            </FilterList>*/}
            <FilterList label="Статус" icon={<TrendingUpIcon />}>
                <FilterListItem
                    label={
                        <>
                            Холодные <Status status="cold" />
                        </>
                    }
                    value={{ status: 'cold' }}
                />
                <FilterListItem
                    label={
                        <>
                            Тёплые <Status status="warm" />
                        </>
                    }
                    value={{ status: 'warm' }}
                />
                <FilterListItem
                    label={
                        <>
                            Горячие <Status status="hot" />
                        </>
                    }
                    value={{ status: 'hot' }}
                />
                <FilterListItem
                    label={
                        <>
                            Уже работаем <Status status="in-contract" />
                        </>
                    }
                    value={{ status: 'in-contract' }}
                />
            </FilterList>
            <FilterList label="Тэги" icon={<LocalOfferIcon />}>
                {data &&
                    data.map(record => (
                        <FilterListItem
                            key={record.id}
                            label={
                                <Chip
                                    label={record?.name}
                                    size="small"
                                    style={{
                                        backgroundColor: record?.color,
                                        border: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                            }
                            value={{ tags: [record.id] }}
                        />
                    ))}
            </FilterList>
            <FilterList
                label="Менеджер"
                icon={<SupervisorAccountIcon />}
            >
                <FilterListItem
                    label="Я"
                    value={{ sales_id: identity && identity.id }}
                />
            </FilterList>
        </Box>
    );
};

import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    EditButton,
    TopToolbar,
    CreateButton,
    Datagrid,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import '../crm.css';
import { ColorField, ColorInput } from 'react-admin-color-picker';

const TagsListActions = () => (
    <TopToolbar>

    </TopToolbar>
);

const TagsPageList = () => {
     const { identity } = useGetIdentity();
    return identity ? (
     <RaList  
        actions={<TagsListActions />}
        perPage={25}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}>
        <Datagrid>
          <TextField label="Название" source="name" />
          <ColorField label="Цвет" source="color" />
          <EditButton />
        </Datagrid>
     </RaList>     
   ) : null;
};

export default TagsPageList;
import { 
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    required
} from 'react-admin';
import { Divider, Box, Grid  } from '@mui/material';
import { ColorField, ColorInput } from 'react-admin-color-picker';

const FunnelCreate = () => {
    return (
        <Create>
            <SimpleForm mb={15}>
                <TextInput label="Название воронки" source="name" validate={required()} />
                <TextInput label="Описание" source="description" />
                <Divider />
                <ArrayInput label="Этапы" source="stages">
                    <SimpleFormIterator >
                        <ColorInput label="Цвет" source="color" picker="Sketch" validate={required()} />
                        <TextInput label="id этапа (латиницей)" source="id" helperText={false} validate={required()} fullwidth/>
                        <TextInput label="Название этапа" source="name" helperText={false} validate={required()} fullwidth/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
}

export default FunnelCreate;
import * as React from 'react';
import { Menu } from 'react-admin';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EmailIcon from '@mui/icons-material/Email';

const MyMenu = (props) => (
    <Menu {...props}>
        <Menu.DashboardItem />
        <Menu.Item to="/deals" primaryText="Сделки" leftIcon={<BusinessCenterIcon />}/>
        <Menu.Item to="/contacts" primaryText="Контакты" leftIcon={<PeopleIcon />}/>
         <Menu.Item to="/companies" primaryText="Компании" leftIcon={<StoreIcon />}/>
        <Menu.Item to="/mails" primaryText="Почта" leftIcon={<EmailIcon />}/>
       
        <Menu.Item to="/tags" primaryText="Редактор тегов" leftIcon={<LocalOfferIcon />}/>
        <Menu.Item to="/funnels" primaryText="Редактор воронок" leftIcon={<FunnelIcon />}/>
       
        <Menu.Item to="/logs" primaryText="Журнал логов" leftIcon={<BookIcon />}/>
        <Menu.Item to="/sales" primaryText="Пользователи" leftIcon={<SupervisedUserCircleIcon />}/>
        
    </Menu>
);

export default MyMenu;
import React from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {Admin, Resource, fetchUtils, useGetIdentity, defaultTheme  } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { authProvider } from './authProvider';
import axios from 'axios';
import { omit } from 'lodash';

import CompanyList from './companies/CompanyList';
import CompaniesCreate from './companies/CompaniesCreate';
import CompaniesEdit from './companies/CompaniesEdit';
import CompaniesShow from './companies/CompaniesShow';

import ContactList from './contacts/ContactList';
import ContactCreate from './contacts/ContactCreate';
import ContactEdit from './contacts/ContactEdit';
import ContactShow from './contacts/ContactShow';

import TagsPageList from './tags/TagsPageList';
import TagEdit from './tags/TagEdit';

import AccountList from './accounts/AccountList';
import AccountCreate from './accounts/AccountCreate';
import AccountEdit from './accounts/AccountEdit';
import AccountShow from './accounts/AccountShow';

import DealList from './deals/DealList';
import DealCreate from './deals/DealCreate';
import DealEdit from './deals/DealEdit';
import DealShow from './deals/DealShow';

import FunnelList from './funnels/FunnelList';
import FunnelCreate from './funnels/FunnelCreate';
import FunnelEdit from './funnels/FunnelEdit';

import LogsList from './logs/LogsList';
import LogShow from './logs/LogShow';

import MailsList from './mails/MailsList';

import Dashboard from './dashboard/Dashboard';

import MyLayout from './design/MyLayout';
import MyLoginPage from './design/MyLoginPage';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

const dataProvider = simpleRestProvider(/*"https://server.prostocrm.pro"*/"https://visionto.ru"/*"http://188.120.254.193:3000"*/, fetchJson);

const MyDataProvider = {
   ...dataProvider,
    create: (resource, params) => {
        if(params.data.picture == null){
            return dataProvider.create(resource, params);
        }

        const filteredObject = omit(params.data, 'picture');
    
        return convertFileToBase64(params.data.picture).then(picture64 => 
            dataProvider.create(resource, { 
                data : {
                    ...filteredObject,
                    avatar : picture64
                }
            })
        );
    },
    update: (resource, params) => {
       /* if (resource !== 'contacts') {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }*/

       // const picture64 = convertFileToBase64(params.data.picture);
        if(params.data.picture == null){
            return dataProvider.update(resource, params);
        }

        const filteredObject = omit(params.data, 'picture');

        return convertFileToBase64(params.data.picture).then(picture64 => 
            dataProvider.update(resource, { 
                ...params,
                data : {
                    ...filteredObject,
                    avatar : picture64
                }
            })
        );
        }

        //return dataProvider.update(resource, params);

        //const formData = new FormData();

  /*for ( const param in params.data ) {

    // 1 file
    if (param === 'picture') {
      formData.append('picture', params.data[param].rawFile);
      continue
    }

    // when using multiple files
    if (param === 'pictures') {
      params.data[param].forEach(file => {
        formData.append('pictures', file.rawFile);
      });
      continue
    }

    formData.append(param, params.data[param]);
  }*/
  /*const picture64 = convertFileToBase64(params.data.picture);

  return axios.put(`http://188.120.254.193:3000/contacts`, JSON.stringify({
      ...params.data,
       avatar: picture64,
      }), {
    headers: {
            'Access-Control-Allow-Origin': '*',
             'Content-Type': 'application/json',
            'authorization': localStorage.getItem('token')
          }
  }).then(({ json }) => ({ data: json }));*/

        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */
        
        // Freshly dropped pictures are File objects and must be converted to base64 strings

        /*const avatarBase = params.data.pictures;

        return Promise.resolve(convertFileToBase64(avatarBase)
            .then(picture64 => dataProvider.update(resource, {
                    data: {
                        ...params.data,
                        avatar: picture64,
                    },
                })
                ));*/
        /*const newPictures = params.data.pictures.filter(
            p => p.rawFile instanceof File
        );
        const formerPictures = params.data.pictures.filter(
            p => !(p.rawFile instanceof File)
        );

        return Promise.all(newPictures.map(convertFileToBase64))
            .then(base64Pictures =>
                base64Pictures.map(picture64 => ({
                    src: picture64,
                    title: `${params.data.title}`,
                }))
            )
            .then(transformedNewPictures =>
                dataProvider.update(resource, {
                    data: {
                        ...params.data,
                        pictures: [
                            ...transformedNewPictures,
                            ...formerPictures,
                        ],
                    },
                })
            );*/
   // },
};



const MyTheme = {
    /*...defaultTheme,
    palette: {
        primary: {
            main: '#7100e2',
            light: '#7100e2',
            dark: '#7100e2',
            },
        secondary: '#dd8500',
        error: '#ff0000',
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
       // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },*/
    ...defaultTheme,
    palette: {
        primary: {
          main: '#7100e2',
        },
        secondary: {
          main: '#dd8500',
        },
  },
};


function App() {
  return (
      <Admin 
      dataProvider={MyDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      layout={MyLayout}
      loginPage={MyLoginPage}
      theme={MyTheme}
      >
      
       <Resource options={{ label: 'Сделки' }} name='deals' list={DealList} create={DealCreate} edit={DealEdit} show={DealShow} />
       <Resource options={{ label: 'Контакты' }} name='contacts' list={ContactList} create={ContactCreate} edit={ContactEdit} show={ContactShow} />
       <Resource options={{ label: 'Компании' }} name='companies' list={CompanyList} create={CompaniesCreate} edit={CompaniesEdit} show={CompaniesShow} />
       <Resource options={{ label: 'Почта' }} name='mails' list={MailsList} />
       <Resource options={{ label: 'Редактор тегов' }} name='tags' list={TagsPageList} edit={TagEdit} />
       <Resource options={{ label: 'Редактор воронок' }} name='funnels' list={FunnelList} create={FunnelCreate} edit={FunnelEdit} />
       <Resource options={{ label: 'Журнал логов' }} name='logs' list={LogsList} show={LogShow} />
       <Resource options={{ label: 'Пользователи' }} name='sales' list={AccountList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
	   
       
        
        
        
      </Admin>
  );
}

export default App;



/*const dataProvider = {
  ...simpleRestProvider("http://188.120.254.193:3000", fetchJson),
  create: (resource, params) => {
    if (resource === 'avatar' && params.data.file) {
      // Perform the file upload
      const formData = new FormData();
      formData.append('file', params.data.file);

      const options = {
        method: 'POST',
        body: formData,
      };

      return fetchUtils.fetchJson("http://188.120.254.193:3000/upload", options);
    }

    // Fallback to the default create method for other resources
    const url = "http://188.120.254.193:3000";
    const options = {
      method: 'POST',
      body: JSON.stringify(params.data),
    };

    return fetchUtils.fetchJson(url, options);
  },
};*/
/* simpleRestProvider("http://188.120.254.193:3000", fetchJson) */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    useRecordContext,
    useRedirect,
    EditButton, DeleteButton, useGetList
} from 'react-admin';
import { Box, Dialog, DialogContent, Typography, Divider } from '@mui/material';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { CompanyAvatar } from '../companies/CompanyAvatar';
import { NotesIterator } from '../notes/NotesIterator';
import { ContactList } from './ContactList';
import { stageNames } from './stages';
import { Avatar } from '../contacts/Avatar';

const DealShow = () => {
    const redirect = useRedirect();

    return (
        <ShowBase>
             <DealShowContent />
        </ShowBase>
    );
};

const DealShowContent = () => {
    const record = useRecordContext();

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'title', order: 'ASC' },
        }
    );

    if(isLoadingFunnels) return null;

    var dealFunnel = funnels.find((item) => item.id === record.funnel);
    var dealStage = dealFunnel.stages.find((item) => item.id === record.stage);

    if (!record) return null;

    return (
        <div>
            <Box display="flex" mt={3}>
                <Box
                    width={100}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <ReferenceField
                        source="company_id"
                        reference="companies"
                        link="show"
                    >
                        <CompanyAvatar />
                    </ReferenceField>
                    <ReferenceField
                        source="company_id"
                        reference="companies"
                        link="show"
                    >
                        <TextField
                            source="name"
                            align="center"
                            component="div"
                        />
                    </ReferenceField>
                </Box>
                <Box ml={2} flex="1">
                    
                    <Typography variant="h5">{record.name}</Typography>
                    <EditButton />
                    <Box display="flex" mt={2}>
                        <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Время начала
                            </Typography>
                            <Typography variant="subtitle1">
                                {record.start_at && format(new Date(record.start_at), 'PP',  { locale: ru })}
                            </Typography>
                        </Box>

                        <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Бюджет
                            </Typography>
                            <Typography variant="subtitle1">
                                {record.amount && record.amount.toLocaleString('ru-RU', {
                                    notation: 'compact',
                                    style: 'currency',
                                    currency: 'RUB',
                                    currencyDisplay: 'narrowSymbol',
                                    minimumSignificantDigits: 3,
                                })}
                            </Typography>
                        </Box>

                        <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Тип
                            </Typography>
                            <Typography variant="subtitle1">
                                {record.type}
                            </Typography>
                        </Box>

                        <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Этап
                            </Typography>
                            <Typography variant="subtitle1">
                                {dealStage && dealStage.name}
                            </Typography>
                        </Box>
                    </Box>

                    <Box mt={2} mb={2}>
                        <Box
                            display="flex"
                            mr={5}
                            flexDirection="column"
                            minHeight={48}
                        >
                            <Typography color="textSecondary" variant="body2">
                                Контакты
                            </Typography>
                            <ReferenceArrayField
                                source="contact_ids"
                                reference="contacts"
                            >
                                <ContactList />
                            </ReferenceArrayField>
                        </Box>
                    </Box>

                    <Box mt={2} mb={2}>
                        <Box
                            display="flex"
                            mr={5}
                            flexDirection="column"
                            minHeight={48}
                        >
                            <Typography color="textSecondary" variant="body2">
                                Менеджер
                            </Typography>
                            <ReferenceField
                                source="sales_id"
                                reference="sales"
                                link="show"
                            >
                                <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
                                <Avatar />
                                <TextField
                                    source="first_name"
                                    ml={2}
                                />
                                <TextField
                                    source="last_name"
                                    ml={1}
                                />
                                </Box>
                            </ReferenceField>
                        </Box>
                    </Box>

                    <Box mt={2} mb={2} style={{ whiteSpace: 'pre-line' }}>
                        <Typography color="textSecondary" variant="body2">
                            Описание
                        </Typography>
                        <Typography>{record.description}</Typography>
                    </Box>
                    <Divider />

                    <Box mt={2}>
                        <ReferenceManyField
                            target="deal_id"
                            reference="dealNotes"
                            sort={{ field: 'date', order: 'DESC' }}
                        >
                            <NotesIterator reference="deals" />
                        </ReferenceManyField>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default DealShow;

import * as React from 'react';
import { TextField, MenuItem } from '@mui/material';

import { Status } from './Status';

export const StatusSelector = ({ status, setStatus, sx }) => (
    <TextField
        select
        value={status}
        onChange={(event) => {
            setStatus(event.target.value);
        }}
        variant="filled"
        label={false}
        margin="none"
        size="small"
        sx={sx}
    >
        <MenuItem value="cold">
            Холодный <Status status="cold" />
        </MenuItem>
        <MenuItem value="warm">
            Тёплый <Status status="warm" />
        </MenuItem>
        <MenuItem value="hot">
            Горячий <Status status="hot" />
        </MenuItem>
        <MenuItem value="in-contract">
            Уже работаем <Status status="in-contract" />
        </MenuItem>
    </TextField>
);

import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    EditButton,
    TopToolbar,
    CreateButton,
    Datagrid,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    DateField,
    Link,
    ShowButton,
    FilterLiveSearch,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import '../crm.css';
import { ColorField, ColorInput } from 'react-admin-color-picker';

const LogsListActions = () => (
    <TopToolbar>
        <ExportButton />
         <FilterLiveSearch />
    </TopToolbar>
);

const LogsListContent = () => {
    const {
        data: logs,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasSecondaryText />;
    }

    return(
                <List>
                {logs.map(log => (
                    <RecordContextProvider key={log.id} value={log}>
                        <ListItem
                            button
                            component={Link}
                            to={`/logs/${log.id}/show`}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(log.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(log.id);
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${log.action} ${log.table}`}
                                secondary={new Date(log.date_time).toLocaleTimeString()}
                            />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>    
    );
}

const LogsList = () => {
    const { identity } = useGetIdentity();

    return identity ? (
     <RaList  
        actions={<LogsListActions />}
        perPage={1000}
        pagination={<Pagination rowsPerPageOptions={[1000, 3000, 5000, 10000]} />}
        sort={{ field: 'date_time', order: 'DESC' }}>
           {/* <LogsListContent /> */}
        <Datagrid>
          <TextField label="Действие" source="action" />
          <TextField label="Таблица" source="table" />
           {/*<TextField label="Предыдущие данные" source="before_action" />
          <TextField label="Новые данные" source="info" />*/}
          <DateField label="Время" source="date_time" showTime locales="ru-RU" />
          <ShowButton />
        </Datagrid>
     </RaList>     
   ) : null;
};

export default LogsList;
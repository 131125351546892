import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Link, Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
    useGetList,
    SimpleList,
    useGetIdentity,
    ReferenceField,
} from 'react-admin';

import { CompanyAvatar } from '../companies/CompanyAvatar';
import { stages, stageNames } from '../deals/stages';
import { Deal } from '../types';

export const DealsPipeline = () => {
    const { identity } = useGetIdentity();
    const { data, total, isLoading } = useGetList(
        'deals',
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'updated_at', order: 'DESC' },
            //filter: { stage: 'non-sorted', sales_id: identity?.id },
        },
        //{ enabled: Number.isInteger(identity?.id) }
    );

    /*const getOrderedDeals = (data) => {
        if (!data) {
            return;
        }
        const deals = [];
        stages
            .filter(stage => stage !== 'won')
            .forEach(stage =>
                data
                    .filter(deal => deal.stage === stage)
                    .forEach(deal => deals.push(deal))
            );
        return deals;
    };*/

    return (
        <>
            <Box display="flex" alignItems="center" marginBottom="1em">
                <Box ml={2} mr={2} display="flex">
                    <MonetizationOnIcon color="disabled" fontSize="large" />
                </Box>
                <Link
                    underline="none"
                    variant="h5"
                    color="textSecondary"
                    component={RouterLink}
                    to="/deals"
                >
                    Новые заявки
                </Link>
            </Box>
            <Card>
                <SimpleList
                    resource="deals"
                    linkType="show"
                    data={data}
                    total={total}
                    isLoading={isLoading}
                    primaryText={deal => deal.name}
                    secondaryText={deal =>
                        `${deal.amount && deal.amount.toLocaleString('ru-RU', {
                            notation: 'compact',
                            style: 'currency',
                            currency: 'RUB',
                            currencyDisplay: 'narrowSymbol',
                            minimumSignificantDigits: 3,
                            // @ts-ignore
                        })} ${stageNames[deal.stage] && ',' + stageNames[deal.stage] || ''}`
                    }
                    leftAvatar={deal => (
                        <ReferenceField
                            source="company_id"
                            record={deal}
                            reference="companies"
                            resource="deals"
                            link={false}
                        >
                            <CompanyAvatar size="small" />
                        </ReferenceField>
                    )}
                />
            </Card>
        </>
    );
};

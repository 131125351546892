import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

const TagEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput label="Название тега" source="name" validate={required()} />
                <ColorInput label="Цвет" source="color" picker="Sketch" validate={required()} />   
            </SimpleForm>
        </Edit>
    );
}

export default TagEdit;

import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
	EditButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';

const FunnelListContent = () => {
    const {
        data: funnels,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasSecondaryText />;
    }

    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>
            <List>
                {funnels.map(funnel => (
                    <RecordContextProvider key={funnel.id} value={funnel}>
                        <ListItem>
                            <ListItemIcon>
                                {funnel.name !== "Main" ? <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(funnel.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(funnel.id);
                                    }}
                                /> : null}
                            </ListItemIcon>
                            <ListItemText
                                primary={funnel.name}
                                secondary={funnel.description}
                            />
							<EditButton />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const FunnelListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Новая воронка"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const FunnelList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={<FunnelListActions />}
            perPage={1000}
            pagination={<Pagination rowsPerPageOptions={[1000, 3000, 5000, 10000]} />}
        >
            <FunnelListContent />
        </RaList>
    ) : null;
};

export default FunnelList;

import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    useInput, required, ImageInput, ImageField
} from 'react-admin';
import { Divider, Box, Grid  } from '@mui/material';

export const ContactInputs = () => {

    return (<Box flex="1" mt={-1}>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Имя" source="first_name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фамилия и/или Отчество" source="last_name" fullWidth validate={required()} />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Должность" source="title" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="company_id" reference="companies">
                    <AutocompleteInput label="Компания" optionText="name" fullWidth />
                </ReferenceInput>
            </Grid>
        </Grid>
        <Divider />
        
        <TextInput source="email" fullWidth />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (основной)" source="phone_number1" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (дополнительный)" source="phone_number2" fullWidth />
            </Grid>
        </Grid>
        <Divider />
        <TextInput label="О контакте" source="background" multiline fullWidth />

        <ImageInput source="picture" label="Аватар" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        {/*<TextInput source="avatar" fullWidth />
          <ImageInput source="avatar" label="Related pictures">
            <ImageField source="src" title="title" />
        </ImageInput>*/}

        <BooleanInput label="Подписан на рассылку" source="has_newsletter"/>

    </Box>);
};

const Spacer = () => <Box width={20} component="span" />;

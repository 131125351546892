import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required
} from 'react-admin';
import { matchPath, useLocation } from 'react-router';

import { DealListContent } from './DealListContent';
import { DealCreate } from './DealCreate';
import { DealEdit } from './DealEdit';
import { DealShow } from './DealShow';
import { OnlyMineInput } from './OnlyMineInput';
import { typeChoices } from './types';


const DealList = () => {
    const { identity } = useGetIdentity();
    const location = useLocation();
    const refresh = useRefresh();
    const [currentFunnel, setCurrentFunnel] = useState(/*{"id": "123", "name": "fallback", "stages": []}*/);
    
    const handleClose = () => {
        refresh();
    };

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'name', order: 'ASC' },
        }
    );

    /*useEffect(() => {
        if (funnels && funnels.length > 1) {
            setCurrentFunnel(funnels[1]);
        }
    }, [funnels]);*/

    useEffect(() => {
      const storedFunnelId = localStorage.getItem('currentFunnelId');
      if (funnels && storedFunnelId) {
        const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
        setCurrentFunnel(selectedFunnel || funnels[0]);
      } else if (funnels && funnels.length > 0) {
        setCurrentFunnel(funnels[0]);
      }
    }, [funnels]);


    useEffect(() => {
      if (currentFunnel) {
        localStorage.setItem('currentFunnelId', currentFunnel.id);
      }
    }, [currentFunnel]);

    if (!identity) return null;

    if(isLoadingFunnels || !currentFunnel || !currentFunnel.stages) return null;

     const handleSelectChange = (value) => {
         console.log('Selected value:', value.target.value);
         setCurrentFunnel(funnels.find((item) => item.id === value.target.value));
    };

    return (
        <>
           
            <List
                perPage={1000}
                sort={{ field: 'index', order: 'ASC' }}
                filters={dealFilters}
                //filterDefaultValues={{ sales_id: identity && identity?.id }} // когда это закомментировано тумблер "только мои сделки" по дефолту отключен
                actions={<DealActions />}
                pagination={false}
                component="div"
            >
            <Form /*record={{ funnel: funnels[1].id }}*/>
                <SelectInput label="Воронка" source="funnel" choices={funnels} defaultValue={currentFunnel.id} onChange={handleSelectChange} validate={required()} />
            </Form>
                <DealListContent stages={currentFunnel.stages}/>
            </List>
        </>
    );
};

const dealFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
    <SelectInput source="type" choices={typeChoices} />,
];

const DealActions = () => {
    return (
        <TopToolbar>
            {/*<FilterButton />*/}
            <ExportButton />
            <CreateButton
                variant="contained"
                label="Новая сделка"
                sx={{ marginLeft: 2 }}
            />
        </TopToolbar>
    );
};

export default DealList;

import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import Box from '@mui/material/Box';

import Logo from '../resources/logo.png';
import Logo1 from '../resources/logo3.png';

const MyAppBar = () => (
    <AppBar sx={{ backgroundColor: 'white', color: 'black'}}>
        <TitlePortal />
        <Box flex={1} /> 
        <div>
            <img src={Logo} alt="Brimp CRM Logo" style={{ height: '28px', marginTop: '10px'}} />
        </div> 
        <Box flex={2} /> 
    </AppBar>
);

export default MyAppBar;
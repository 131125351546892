import * as React from 'react';
import { EditBase, Form, Toolbar, useEditContext, ShowButton } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';

import { Avatar } from '../contacts/Avatar';
import { AccountInputs } from './AccountInputs';
import '../crm.css';

const AccountEdit = () => (
    <EditBase redirect="show">
        <AccountEditContent />
    </EditBase>
);

const AccountEditContent = () => {
    const { isLoading, record } = useEditContext();
    if (isLoading || !record) return null;

    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <ShowButton label="Показать пользователя" />
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Box mr={2}>
                                        <Avatar />
                                    </Box>
                                    <AccountInputs link="edit" username={record.username} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

export default AccountEdit;
